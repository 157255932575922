/*------------------------------------------------------------------
  Project: Resume
  Author: The_Krishna
  Last change: 01/06/2024
  Primary use:
------------------------------------------------------------------ */
/*-----------------------[Table of contents]------------------------
1.Default CSS
2.Page Loader CSS
3.Side Menu CSS
4.CV Dowload Button CSS
5.Main Containe and section One CSS
6.Brand Marquee Section CSS
7.About Section CSS
8.Education-Experience CSS
9.coding-skill-section CSS
10.design-skill-section CSS
11.Award-section CSS
12.Services-section CSS
13.Porfoilo-section CSS
14.Porfoilo-section containe pop up CSS
15.Gallery CSS
16.Pricing Section CSS
17.Testimonials Section CSS
18.Section Blog CSS
19.Contact Section CSS
20.Whole Page Aniamtion CSS
------------------------------------------------------------------ */
/*-----------------------[ 1.Default CSS ]------------------------*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "DM Sans", sans-serif !important;
    font-style: normal;
    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a {
    text-decoration: none;
}

ol,
ul {
    padding-left: 0rem !important;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0rem;
}

ul li {
    list-style: none;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: #00CC97;
    background-clip: content-box;
}

::-webkit-scrollbar-track {
    background-color: #0F141C;
}

/*-----------------------[ 2.Page Loader CSS ]------------------------*/
.page-loader {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #0F141C;
    z-index: 1000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-loader img {
    width: 200px;
    height: auto;
}

/*-----------------------[ 3.Side Menu CSS ]------------------------*/
.fix-bg {
    background: #0F141C;
    background-image: url('../images/noice-img.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-blend-mode: soft-light;
}

.Ellipse1 {
    position: absolute;
    top: 0;
    left: 0;
    animation: moveEllipse1 20s linear infinite, changeColor 20s linear infinite;
    width: 340px;
    height: 340px;
    border-radius: 250px;
    background-color: rgb(0 204 151 / 24%);
    filter: blur(250px);
    z-index: -1;
}

.Ellipse2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 340px;
    height: 340px;
    border-radius: 250px;
    background-color: rgb(255 117 156 / 24%);
    filter: blur(250px);
    z-index: -1;
    animation: moveEllipse2 20s linear infinite, changeColor 20s linear infinite;
}

@keyframes moveEllipse1 {
    0% {
        top: 0;
        left: 0;
    }

    25% {
        top: 0;
        left: 100%;
        transform: translateX(-100%);
    }

    50% {
        top: 100%;
        left: 100%;
        transform: translate(-100%, -100%);
    }

    75% {
        top: 100%;
        left: 0;
        transform: translateY(-100%);
    }

    100% {
        top: 0;
        left: 0;
    }
}

@keyframes changeColor {

    0%,
    100% {
        background-color: rgb(0, 204, 151, 24%);
        filter: blur(250px);
    }

    20% {
        background-color: rgba(255, 0, 0, 0.8);
        filter: blur(200px);
    }

    40% {
        background-color: rgba(0, 0, 255, 0.8);
        filter: blur(150px);
    }

    60% {
        background-color: rgba(255, 255, 0, 0.8);
        filter: blur(200px);
    }

    80% {
        background-color: rgba(0, 255, 0, 22);
        filter: blur(250px);
    }
}

@keyframes moveEllipse2 {
    0% {
        top: 100%;
        right: 0;
        transform: translateY(-100%);
    }

    25% {
        top: 100%;
        right: 100%;
        transform: translate(100%, -100%);
    }

    50% {
        top: 0;
        right: 100%;
        transform: translateX(100%);
    }

    75% {
        top: 0;
        right: 0;
    }

    100% {
        top: 100%;
        right: 0;
        transform: translateY(-100%);
    }
}

.menu-toggle-button {
    display: none;
}

.side-menu::-webkit-scrollbar {
    display: none;
}

.side-menu {
    max-width: 250px;
    width: 100%;
    height: 100vh;
    background: #171B22;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    overflow: auto;
}

.profile-img-main {
    text-align: center;
    padding: 20px 20px 0 20px;
}

.profile-img-main img {
    max-width: 100%;
}

h1 {
    color: var(--1, #FFF) !important;
    text-align: center;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: normal !important;
    padding: 15px 0 5px 0;
}

h1 span {
    font-weight: 400;
}

.profile-img-main h2 {
    color: var(--14, #909090);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.profile-media-icons-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    gap: 10px;
}

.profile-media-icons {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #0F141C;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.profile-media-icons:hover {
    background: #FF759C;
}

.menu-list-main ul li {
    padding: 10px 20px;
    border-top: 1px solid #ffffff38;
}

#contact-line {
    border-bottom: 1px solid #ffffff38;
}

.menu-list-main ul li a {
    color: var(--8, #909090);
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.active-menu-action a:hover {
    filter: brightness(0) saturate(100%) invert(55%) sepia(64%) saturate(887%) hue-rotate(120deg) brightness(94%) contrast(101%) !important;
}

.active-menu-action a.active {
    filter: brightness(0) saturate(100%) invert(55%) sepia(64%) saturate(887%) hue-rotate(120deg) brightness(94%) contrast(101%) !important;
}

/*-----------------------[ 4.CV Dowload Button CSS ]------------------------*/
#wrap {
    margin: 20px 20px;
    text-align: center;
}

.btn-slide {
    position: relative;
    display: inline-block;
    height: 60px;
    width: 100%;
    line-height: 50px;
    padding: 0;
    border-radius: 30px;
    background: #0F141C;
    transition: .5s;
}

.btn-slide:hover {
    background-color: #FF759C;
}

.btn-slide:hover span.circle {
    left: 100%;
    margin-left: -53px;
    background-color: #fdfdfd;
}

.btn-slide:hover span.circle .download-svg {
    fill: #FF759C;
}

.btn-slide:hover span.title {
    left: 40px;
    opacity: 0;
}

.btn-slide:hover span.title-hover {
    opacity: 1;
    left: 40px;
}

.btn-slide span.circle {
    display: block;
    background-color: #FF759C;
    position: absolute;
    margin: 5px;
    line-height: 42px;
    height: 48px;
    width: 48px;
    top: 1px;
    left: 0;
    transition: .5s;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-slide span.title,
.btn-slide span.title-hover {
    position: absolute;
    top: 7px;
    left: 90px;
    text-align: center;
    margin: 0 auto;
    transition: .5s;
    color: var(--1, #FFF);
    font-size: 16px;
    font-weight: 500;
}

.btn-slide span.title,
.btn-slide span.title-hover {
    left: 80px;
}

.btn-slide span.title-hover {
    left: 80px;
    opacity: 0;
}

/*-----------------------[ 5.Main Containe and Section One CSS ]------------------------*/
.main-containe {
    background: #0F141C;
    height: 100vh;
    overflow-x: auto;
    padding-bottom: 0;
    max-width: 1040px;
    width: 100%;
    float: right;
    padding: 0 20px 0 60px;
}

.section-one {
    padding-top: 80px;
    padding-bottom: 140px;
}

.mdrzn-main-text {
    color: var(--1, #FFF);
    font-size: 96px;
    font-weight: 700;
    line-height: 120px;
}

.mdrzn-main-text span {
    font-weight: 400;
}

.back-End-dev {
    color: var(--7, #FF759C);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    padding: 20px 0;
}

.best {
    color: var(--14, #909090);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    max-width: 390px;
}

.me {
    max-width: 100%;
    padding-top: 60px;
}

.section-one-btns-main {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 40px;
}

.btn-hover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    text-decoration: none;
    text-align: center;
    border-radius: 32px;
    color: #0F141C;
    background: #00CC97;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    transition: all .35s;
    z-index: 1;
    padding: 16px 30px;
}

.btn-hover2 {
    background: #FFDB59;
}

.btn-hover:before,
.btn-hover:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background: #FF759C;
    z-index: -1;
    transition: all .35s;
}

.btn-hover:after {
    transition-delay: .2s;
}

.btn-hover:hover {
    color: #fff;
}

.btn-hover:hover:before,
.btn-hover:hover:after {
    top: 0;
}

.flower {
    filter: brightness(0) saturate(100%) invert(50%) sepia(73%) saturate(413%) hue-rotate(323deg) brightness(103%) contrast(101%);
    animation: rotation infinite 9s linear;
    position: absolute;
    top: 113px;
    left: 66px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.circular-img {
    position: absolute;
    top: 0;
    left: -50px;
    animation: rotation infinite 9s linear;
}

.worked-box {
    border-radius: 15px;
    background: var(--5, #171B22);
    box-shadow: 0px 40px 40px -25px rgba(19, 31, 47, 0.60);
    padding: 20px;
    width: 100%;
    max-width: 330px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
}

.worked-more {
    color: var(--1, #FFF);
    font-size: 20px;
    line-height: normal;
    /* padding-bottom: 15px; */
}

.client-img-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.client-img1 {
    position: relative;
}

.client-img {
    border-radius: 50%;
    cursor: pointer;
}

.client-img:hover {
    z-index: 10;
}

.client-img2 {
    position: absolute;
    left: 30px;
}

.client-img3 {
    position: absolute;
    left: 60px;
}

.client-img4 {
    position: absolute;
    left: 90px;
}

.worked-more2 {
    font-size: 16px;
    padding: 0;
    max-width: 60px;
}

.worked-more2::before {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #FFF;
    position: absolute;
    top: 22px;
    right: 90px;
}

/*-----------------------[ 6.Brand Marquee Section CSS ]------------------------*/
.Marquee-main h3 {
    color: var(--1, #FFF);
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
}

.logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: flex;
}

.logos-slide {
    animation: 40s slide infinite linear;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.marquee__content {
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    align-items: center;
    padding-top: 20px;
}

.marquee-img-main {
    border: 1px solid #63636385;
    padding: 0 52px;
    border-radius: 40px;
    cursor: pointer;
    height: 80px;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marquee-img-main {
    margin: 0 10px;
}

.brand-logos {
    width: 80px;
    max-height: 40px;
    object-fit: contain;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

/*-----------------------[ 7.About Section CSS ]------------------------*/
.About-section {
    padding: 80px 0;
}

.section-heading-text {
    color: var(--1, #FFF);
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    position: relative;
    display: flex;
    align-items: center;
}

.heading-container {
    display: flex;
    align-items: baseline;
    gap: 15px;
}

.heading-container .line {
    flex-grow: 1;
    height: 2px;
    background: var(--14, #909090);
    mix-blend-mode: hard-light;
}

.section-sub-text {
    color: var(--14, #909090);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.section-sub-text span {
    color: var(--14, #FFF);
}

.about-sub-text {
    padding: 40px 0 30px 0;
}

.about-detail {
    color: var(--14, #909090);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    max-width: 200px;
    width: 100%;
}

.about-detail-info {
    color: var(--1, #FFF);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.about-detail-info.email {
    cursor: pointer;
}

.about-detail-main {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.about-detail-info::before {
    content: ":";
    color: #909090;
    margin-right: 30px;
}

/*-----------------------[ 8.Education-Experience CSS ]------------------------*/
.education-heading-text {
    color: var(--1, #FFF);
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 30px;
    position: relative;
}

.education::before {
    content: "";
    border-left: 2px solid #FFF;
    position: absolute;
    top: 0;
    left: 11px;
    bottom: 0;
    z-index: -1;
}

.education {
    display: flex;
    position: relative;
    z-index: 1;
    margin-top: 20px;
}

.small_yellow_border {
    border: 2px solid #FFF;
    border-radius: 100%;
    padding: 5px;
    background-color: #000;
}

.small_yellow_circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #FF759C;
}

.bachelor {
    color: #FFF;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 10px;
    transition: all 0.3s;
}

.education:hover .bachelor {
    color: #FF759C;
}

.university {
    padding-bottom: 15px;
    font-size: 18px !important;
}

.cursus {
    color: #909090;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    transition: all 0.3s;
}

.education:hover .cursus {
    color: #FFF;
}

.small_yellow_border_main {
    padding-left: 20px;
}

/*-----------------------[ 9.coding-skill-section CSS ]------------------------*/
.coding-skill-section {
    padding: 80px 0;
}

#progress {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 30px;
}

.progress-item {
    display: flex;
    width: 146px;
    height: 146px;
    border-radius: 50%;
    font-size: 0;
    animation: .4s ease-out reverse;
}

.progress-item::after {
    content: attr(data-value);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 130px;
    margin: 8px;
    border-radius: 50%;
    background: #0F141C;
    color: var(--1, #FFF);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
}

/*-----------------------[ 10.design-skill-section CSS ]------------------------*/
.design-skill-sub-section {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 46px 0 80px 0;
    justify-content: space-between;
}

.design-skills-img-main {
    border-radius: 40px;
    border: 2px solid var(--8, #00CC97);
    background: rgba(0, 204, 151, 0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    max-width: 179px;
    width: 100%;
    height: 80px;
}

.design-skills-img-main img {
    max-width: 100%;
    height: auto;
    width: 50px;
    object-fit: contain;
}

.design-skills-img-main img.brand-logos {
    filter: brightness(1) saturate(100%);
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.design-skills-img-main img.html-css-logo {
    width: 60px;
    height: auto;
    object-fit: contain;
    filter: none;
}

.photoshop {
    border: 2px solid #31A8FF;
    background: rgba(49, 168, 255, 0.10);
}

.photoshop-text p:nth-of-type(2) {
    color: var(--1, #31A8FF) !important;
}

.adobe-xd {
    border: 2px solid #eb78ff;
    background: rgba(235, 120, 255, 0.1);
}

.adobe-xd-text p:nth-of-type(2) {
    color: #eb78ff !important;
}

.sketch {
    border: 2px solid #FF7BE5;
    background: linear-gradient(135deg, rgba(255, 123, 229, 0.1), rgba(146, 0, 59, 0.1));
}

.sketch-text p:nth-of-type(2) {
    color: #FF7BE5 !important;
}

.invision {
    border: 2px solid #07B9CE;
    background: linear-gradient(135deg, rgba(7, 185, 206, 0.1), rgba(57, 105, 231, 0.1), rgba(125, 42, 231, 0.1));
}

.invision-text p:nth-of-type(2) {
    background: linear-gradient(90deg, #07B9CE, #3969E7, #7D2AE7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skill-counter-main p:first-of-type {
    color: var(--1, #FFF);
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
    padding-bottom: 5px;
}

.skill-counter-main p:nth-of-type(2) {
    color: var(--8, #00CC97);
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
}

.skill-counter-main p {
    margin-bottom: 0px !important;
}

/*-----------------------[ 11.Award-section CSS ]------------------------*/
.box-item {
    position: relative;
}

.awards-row {
    gap: 24px 0;
    padding-top: 46px;
}

.flip-box {
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
}

.flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box-front,
.flip-box-back {
    background-size: cover;
    background-position: center;
    min-height: 180px;
    -ms-transition: transform 0.7s cubic-bezier(.4, .2, .2, 1);
    transition: transform 0.7s cubic-bezier(.4, .2, .2, 1);
    -webkit-transition: transform 0.7s cubic-bezier(.4, .2, .2, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #171B22;
    border-radius: 20px;
}

.flip-box .inner {
    position: absolute;
    left: 0;
    width: 100%;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    padding: 20px;
    transform: translateY(-50%) translateZ(60px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
}

.award-yer {
    color: var(--14, #909090);
    text-align: right;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}

.winner-award {
    max-width: 100%;
    padding: 0 0 20px;
}

.years-award-img {
    display: flex;
    justify-content: space-between;
}

.inner p {
    margin-bottom: 0px !important;
}

.award-interior {
    color: var(--1, #FFF);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.award-winner-text {
    color: var(--11, #FF7D61);
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 10px;
}

.flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-back-text {
    font-size: 18px;
    color: #FFF;
    line-height: 28px;
    text-align: center;
}

.flip-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

/*-----------------------[ 12.Services-section CSS ]------------------------*/
.Services-section {
    padding: 80px 0;
}

.services-product-text {
    color: var(--1, #FFF);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 30px 0 20px;
}

.visoion-text {
    color: var(--14, #909090);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.services-row {
    padding: 30px 0 0;
}

.services-img {
    width: 83px;
    height: 83px;
    border-radius: 50%;
    background: #FF759C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-img2 {
    background: #00CC97;
}

.services-img3 {
    background: #6F39FD;
}

.services-img4 {
    background: #FF7D61;
}

.services-text {
    color: var(--1, #FFF);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 15px 0 10px 0;
}

.molestie {
    color: var(--14, #909090);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.down-arrow-svg {
    position: relative;
    margin-top: 15px;
    border-bottom: 2px solid #FFF;
    transition: all 0.3s;
}

.services-column:hover .down-arrow-svg {
    rotate: -45deg;
    border-bottom: 2px solid transparent;
}

.services-column:hover .services-text {
    color: #FF759C;
}

.services-column:hover .services-text2 {
    color: #00CC97;
}

.services-column:hover .services-text3 {
    color: #6F39FD;
}

.services-column:hover .services-text4 {
    color: #FF7D61;
}

.services-column:hover .molestie {
    color: #FFF;
}

.services-popup-text {
    padding-bottom: 10px;
}

.services-column a {
    text-decoration: none;
}

/*-----------------------[ 13.Porfoilo-section CSS ]------------------------*/
.portfolios-group-main img {
    border-radius: 20px;
    max-width: 100%;
}

.portfolios-group-main {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.Corporate {
    color: var(--1, #FFF);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding-top: 10px;
}

.Corporate-sub {
    color: var(--14, #909090);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    padding-top: 5px;
}

.second-row-portfolio {
    margin-top: 30px;
}

.image-container {
    position: relative;
    cursor: pointer;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff759ccc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.vimeo-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(181deg) brightness(103%) contrast(103%);
}

.overlay-text {
    color: #FFF;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 1px;
}

.image-container:hover .overlay {
    opacity: 1;
}

.view-all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

/*-----------------------[ 14.Porfoilo-section containe pop up CSS ]------------------------*/
.popup-wrap {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    background: linear-gradient(131deg, rgba(9, 16, 26, 0.28) 0%, rgba(9, 16, 26, 0.28) 100%);
    backdrop-filter: blur(4px);
    z-index: 600;
}

.popup-box {
    width: 850px;
    height: 100vh;
    overflow: auto;
    padding: 50px;
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    background: #171B22;
    text-align: center;
}

.popup-box .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.transform-in,
.transform-out {
    display: block;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.transform-in {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

.transform-out {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
}

.pop-up-img1 {
    max-width: 100%;
    border-radius: 15px;
}

.created_by,
.cuberto {
    color: #909090;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    padding: 10px 0;
}

.cuberto {
    color: #FFF !important;
}

.corpo {
    font-size: 25px;
    line-height: 35px;
    font-weight: 500;
    color: #FFF;
    padding-top: 30px;
}

.corpo-sub {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: #FFF;
    padding: 10px 0 30px;
}

.cuberto_main {
    display: flex;
    justify-content: space-between;
}

.Ornare {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #909090;
    padding-top: 30px;
}

.pop-up-img-section2 img {
    max-width: 100%;
    border-radius: 20px;
    margin: 30px 0;
}

/*-----------------------[ 15.Gallery CSS ]------------------------*/
.main .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    justify-content: center;
    align-items: center;
}

.main .card {
    color: #252a32;
    border-radius: 2px;
    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}

.main .card-image {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 70%;
    background: #ffffff;
}

.main .card-image img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mobile_app {
    color: var(--1, #FFF);
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    padding-bottom: 10px;
}

.popup-container {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3);
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(131deg, rgba(9, 16, 26, 0.28) 0%, rgba(9, 16, 26, 0.28) 100%);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
}

.popup-content {
    background-color: #171B22;
    margin: auto;
    padding: 50px;
    width: 800px;
    height: 100vh;
    overflow: auto;
}

.popup-content a.close {
    color: #FFF;
    float: right;
    font-size: 35px;
    font-weight: bold;
    background: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.popup-container:target {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.popup-container h3 {
    margin: 10px;
}

.popup-style {
    transform: scale(0.3);
}

.popup-style:target {
    transform: scale(1);
}

/*-----------------------[ 16.Pricing Section CSS ]------------------------*/
.Pricing-section {
    padding: 80px 0;
}

.pricing-section-main {
    display: flex;
    align-items: center;
    margin: 30px 0 20px 0;
}

.basic-plain-box {
    padding: 20px;
    background: #FF759C;
    text-align: center;
    max-width: 230px;
    width: 100%;
}

.basic-plain-box2 {
    background: #6F39FD;
}

.basic-plain-box3 {
    background: #FF7D61;
}

.basic-plain-text {
    color: var(--1, #FFF);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding-top: 20px;
}

.basic-plain-box-sub {
    padding: 46px 52px;
    background: #FFF;
    max-width: 230px;
    width: 100%;
}

.mon {
    color: var(--12, #0F141C);
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
}

.mon sup {
    font-weight: 500;
    top: -15px;
}

.mon span {
    font-size: 20px;
}

.pricing-plaines-name {
    color: var(--14, #909090);
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    display: flex;
    align-items: center;
    align-items: stretch;
    gap: 15px;
    margin-bottom: 20px;
}

.pricing-plaines-name::before {
    content: url(../images/svg/check-mark.svg);
    height: 30px;
}

.taxes {
    color: var(--14, #909090);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 40px;
    max-width: 177px;
}

.development-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 20px 20px 50px;
    background: var(--5, #171B22);
}

.choose-plain-btn-main {
    padding: 50px 60px;
    border-left: 2px solid #63636385;
}

.choose-plain-btn-main .wrapper {
    max-width: 177px;
}

/*-----------------------[ 17.Testimonials Section CSS ]------------------------*/
.testament {
    color: var(--14, #909090);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 30px 0;
}

.colan-icon-img-main {
    width: 64px;
    height: 64px;
    background: #FF759C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.avix {
    color: var(--14, #909090);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.client-name {
    color: var(--1, #FFF);
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    padding-top: 20px;
}

.kello {
    color: var(--14, #909090);
    font-size: 16px;
    font-weight: 500;
}

.slick-slide {
    margin: 0 0.5px;
}

.slick-dots {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;
    align-items: baseline;
}

.slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #909090;
    mix-blend-mode: hard-light;
    border: none;
    color: #909090 !important;
    font-size: 1px;
}

li.slick-active button {
    background: #00CC97;
    mix-blend-mode: normal;
}

li.slick-active {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #00CC97;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-list {
    height: 380px !important;
}

.slick-dots li button:before {
    font-size: 11px !important;
    color: #909090 !important;
    opacity: 1 !important;
    width: 16px !important;
}

.slick-dots li.slick-active button:before {
    color: #00CC97 !important;
}

/*-----------------------[ 18.Section Blog CSS ]------------------------*/
.blog-section {
    padding: 80px 0px;
}

.blog-main {
    display: flex;
    align-items: center;
    gap: 60px;
    margin: 30px 0;
}

.blog-img-section {
    background-image: url('../images/blog-img1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 250px;
    width: 100%;
    max-width: 330px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 20px;
}

.blog-img-section:hover {
    filter: blur(1px);
}

.blog-img-section2 {
    background-image: url('../images/blog-img2.png');
}

.blog-img-section3 {
    background-image: url('../images/blog-img3.png');
}

.blog-date {
    width: 64px;
    height: 64px;
    border-radius: 10px;
    background: var(--1, #FFF);
    box-shadow: 0px 10px 20px 0px rgba(2, 5, 10, 0.40);
    color: var(--12, #0F141C);
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    margin: 10px;
}

.blog-date span {
    color: var(--12, #0F141C);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.post-date {
    color: var(--14, #909090);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}

.steps {
    color: var(--5, #FFF);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 10px 0 20px 0;
}

.est {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--14, #909090);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.btn-arrow-right {
    margin-left: 10px;
}

.btn-hover:hover .btn-arrow-right path {
    fill: #FFFF;
}

.blog-btn {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
}

.blog-popup-img1 {
    max-width: 100%;
    border-radius: 30px;
    margin: 30px 0;
}

.simple-steps {
    color: #FFFF;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    margin-top: 20px;
}

.date-onner-coment-main {
    display: flex;
    align-items: center;
    gap: 40px;
}

.date-onner-coment-sub-text {
    color: #FFF;
}

.Icon-Set {
    margin-right: 5px;
}

.blog-sit-text {
    font-size: 18px;
    line-height: 28px;
    color: #909090;
    font-weight: 400;
    padding-bottom: 10px;
}

.blog-media-main p {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
}

.blog-media-main {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 30px;
}

/*-----------------------[ 19.Contact Section CSS ]------------------------*/
.mobile-icon-main {
    width: 80px;
    height: 80px;
    background: #6F39FD;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-box-contact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.contact-box-main {
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
    background: var(--5, #171B22);
    padding: 10px;
}

.contact-email-text {
    color: var(--14, #909090);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    padding-bottom: 8px;
}

.contact-email a {
    color: var(--5, #FFF);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-decoration: none;
}

.main-box-contact p {
    margin-bottom: 0px !important;
}

.contact-email a:hover {
    color: #6F39FD;
}

.form {
    display: flex;
    flex-direction: column;
    position: relative;
}

.form-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: space-between;
    align-items: center;
    gap: 30px;
    margin-top: 60px;
}

.form.active label {
    font-size: 16px;
    color: #55E6A5;
}

.form__input {
    padding: 8px 0;
    border: none;
    background: transparent;
    border-bottom: 2px solid #63636385;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.form__input:focus {
    outline: none;
    border-bottom: 2px solid #55E6A5;
}

.form__input:focus:invalid {
    border-bottom: 2px solid #55E6A5;
}

.form__label {
    color: #909090;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
}

.subject-input-main {
    margin: 30px 0;
}

::placeholder {
    color: var(--1, #FFF);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.map-iframe {
    margin: 30px 0;
    width: 100%;
    height: 340px;
    border-radius: 20px;
}

.footer {
    color: var(--14, #909090);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    padding: 30px 0;
}

.footer span a {
    color: var(--1, #FFF);
    text-decoration: none;
    margin-left: 5px;
}

.footer span a:hover {
    color: #00CC97;
}

/*-----------------------[ 20.Whole Page Aniamtion CSS ]------------------------*/
.fade_up,
.fade_down,
.zoom_in,
.zoom_out {
    opacity: 0;
    transition: all 2s;
}

.fade_up {
    transform: translateY(-100%);
}

.fade_down {
    transform: translateY(100%);
}

.zoom_in {
    transform: scale(0.5);
}

.zoom_out {
    transform: scale(1.5);
}

.fade_right {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 2s;
}

.fade_left {
    opacity: 0;
    transform: translateX(100%);
    transition: all 2s;
}

.flip_left {
    opacity: 0;
    transform: perspective(400px) rotateY(-90deg);
    transition: all 2s;
}

.flip_right {
    opacity: 0;
    transform: perspective(400px) rotateY(90deg);
    transition: all 2s;
}

.flip_up {
    opacity: 0;
    transform: perspective(400px) rotateX(-90deg);
    transition: all 2s;
}

.flip_down {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
    transition: all 2s;
}

.show {
    opacity: 1;
    transform: translateY(0) scale(1);
}

.popup-wrap {
    display: none;
    /* Other styles */
}

.popup-wrap.visible {
    display: block;
    /* Other styles */
}

.vimeo-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vimeo-modal {
    /* padding: 20px; */
    max-width: 640px;
    width: 100%;
    position: relative;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close-btn {
    position: absolute;
    top: -50px;
    right: -10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    height: 25px;

}

.youtube-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube-modal {
    border-radius: 8px;
    max-width: 640px;
    width: 100%;
    position: relative;
}

.close-btn-youtube {
    position: absolute;
    top: -50px;
    right: -50px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    height: 25px;
}

.soundcloud-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.soundcloud-modal {
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    position: relative;
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem 0;
}

.skill-item {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(99, 99, 99, 0.52);
    border-radius: 12px;
    padding: 1.5rem;
    transition: all 0.3s ease;
}

.skill-item:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.1);
}

.skill-item .bachelor {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
}

.skill-item .cursus {
    margin: 0;
    line-height: 1.5;
}